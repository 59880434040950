import React, { useState } from "react";
import axios from "axios";

const GerenciadorRespostasImagens = () => {
  const [response, setResponse] = useState(""); // Estado compartilhado para a resposta
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado para erros

  // Função para ler descrições (Histórico Gerados)
  const lerDescricoes = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(
        "http://89.116.214.151:3000/v1/lerRespostas200Ok"
      );
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setError(
        JSON.stringify(
          error.response ? error.response.data : error.message,
          null,
          2
        )
      );
    } finally {
      setLoading(false);
    }
  };

  // Função para deletar respostas
  const deletarRespostas = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.delete(
        "http://89.116.214.151:3000/v1/deletarRespostas200Ok"
      );
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setError(
        JSON.stringify(
          error.response ? error.response.data : error.message,
          null,
          2
        )
      );
    } finally {
      setLoading(false);
    }
  };

  // Função para listar imagens processadas
  const listarImagensProcessadas = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(
        "http://89.116.214.151:3000/v1/listarImagensSucesso"
      );
      const imagensProcessadas = Array.isArray(res.data.imagens)
        ? res.data.imagens
        : [];
      setResponse(
        imagensProcessadas.length > 0
          ? imagensProcessadas.map((imagem) => (
              <div key={imagem.nome}>
                <p>{imagem.nome}</p>
                <img src={imagem.thumbnail} alt={imagem.nome} />
              </div>
            ))
          : "Nenhuma imagem encontrada."
      );
    } catch (error) {
      setError("Erro ao listar imagens processadas.");
    } finally {
      setLoading(false);
    }
  };

  // Função para deletar imagens processadas
  const deletarImagensProcessadas = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.delete(
        "http://89.116.214.151:3000/v1/deletarImagensProcessadas"
      );
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setError(
        JSON.stringify(
          error.response ? error.response.data : error.message,
          null,
          2
        )
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <section>
      <h2>Histórico Gerados (via agendamento)</h2>

      {/* Grupo de botões */}
      <div className="button-group">
        <button onClick={lerDescricoes} disabled={loading}>
          {loading ? "Carregando..." : "Descrições"}
        </button>

        <button onClick={deletarRespostas} disabled={loading}>
          {loading ? "Deletando..." : "Resetar"}
        </button>

        <button onClick={listarImagensProcessadas} disabled={loading}>
          {loading ? "Carregando..." : "Listar Imagens"}
        </button>

        <button onClick={deletarImagensProcessadas} disabled={loading}>
          {loading ? "Deletando..." : "Resetar"}
        </button>
      </div>

      {/* Exibir a resposta da API ou o erro */}
      <div className="response">
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <pre>{error}</pre>
        ) : (
          <div>{response}</div>
        )}
      </div>
    </section>
  );
};

export default GerenciadorRespostasImagens;
