import React from "react";
import "./styles.css"; // Certifique-se de que está usando './' e não um caminho absoluto
import EnviarImagens from "./components/EnviarImagens";
import GerenciarArquivo from "./components/GerenciarArquivo";
import BuscarDescricoesDasImagens from "./components/BuscarDescricoesDasImagens";
import AgendarChamada from "./components/AgendarChamada";
import RemoverAgendamento from "./components/RemoverAgendamento";
import GerenciadorRespostasImagens from "./components/GerenciadorRespostasImagens";
import AtualizarApiKey from "./components/atualizarKey";
import LerApiKey from "./components/lerKey";
import LerEmailTo from "./components/lerEmailAtual";
import AtualizarEmailTo from "./components/visualizarEmailAtual";
import Laudos from "./components/laudosDisponiveis";

function App() {
  return (
    <div className="container">
      <EnviarImagens />
      <BuscarDescricoesDasImagens />
      <Laudos />
      <AgendarChamada />
      <RemoverAgendamento />
      <GerenciadorRespostasImagens />

      <GerenciarArquivo />
      <LerEmailTo />
      <AtualizarEmailTo />
      <AtualizarApiKey />
      <LerApiKey />
    </div>
  );
}

export default App;
