import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000/v1";

function GerenciarArquivo() {
  const [conteudoAtual, setConteudoAtual] = useState(""); // Estado para o conteúdo atual
  const [novoConteudo, setNovoConteudo] = useState(""); // Estado para o novo conteúdo
  const [responseMessage, setResponseMessage] = useState(""); // Estado para a mensagem de resposta
  const [errorMessage, setErrorMessage] = useState(""); // Estado para a mensagem de erro
  const [loading, setLoading] = useState(false); // Estado de carregamento

  // Função para ler a descrição
  const lerArquivo = async () => {
    setLoading(true);
    setErrorMessage(""); // Limpa mensagens de erro anteriores
    setResponseMessage(""); // Limpa mensagens de resposta anteriores
    try {
      const res = await axios.get(`${baseURL}/lerArquivo`);
      setConteudoAtual(res.data.content); // Define o conteúdo do arquivo no estado
      setResponseMessage("Conteúdo do arquivo carregado com sucesso.");
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao ler o arquivo.";
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  // Função para atualizar a descrição
  const atualizarArquivo = async () => {
    if (!novoConteudo) {
      setErrorMessage(
        "Por favor, insira o novo conteúdo para atualizar o arquivo."
      );
      return;
    }

    setLoading(true);
    setErrorMessage(""); // Limpa mensagens de erro anteriores
    setResponseMessage(""); // Limpa mensagens de resposta anteriores
    try {
      const res = await axios.post(
        `${baseURL}/atualizarArquivo`,
        novoConteudo,
        {
          headers: {
            "Content-Type": "text/plain",
          },
        }
      );
      setResponseMessage("Arquivo atualizado com sucesso.");
      setConteudoAtual(novoConteudo); // Atualiza o conteúdo exibido com o novo conteúdo
      setNovoConteudo(""); // Limpa o campo de entrada do novo conteúdo
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao atualizar o arquivo.";
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="section-text-area-gerenciar-arquivo">
      <h2>Comando (Prompt)</h2>

      {/* Exibe o conteúdo atual do arquivo */}
      <div className="conteudo-atual">
        <h3></h3>
        <p>{conteudoAtual || ""}</p>
      </div>

      {/* Campo de texto para inserir o novo conteúdo */}
      <textarea
        value={novoConteudo}
        onChange={(e) => setNovoConteudo(e.target.value)}
        placeholder="Digite o novo conteúdo"
        className="input"
        disabled={loading} // Desativa enquanto está carregando
      ></textarea>

      {/* Grupo de botões */}
      <div className="button-group">
        {/* Botão para ler a descrição */}
        <button onClick={lerArquivo} disabled={loading}>
          {loading ? "Carregando..." : "Ler Descrição"}
        </button>

        {/* Botão para atualizar a descrição */}
        <button onClick={atualizarArquivo} disabled={loading}>
          {loading ? "Atualizando..." : "Atualizar Descrição Atual"}
        </button>
      </div>

      {/* Exibição das mensagens de resposta ou erro */}
      <div id="resposta-comando-prompt" className="response">
        {loading && <p>Processando...</p>}
        {responseMessage && <p style={{ color: "green" }}>{responseMessage}</p>}
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    </section>
  );
}

export default GerenciarArquivo;
