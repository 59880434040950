import React, { useState } from "react";
import axios from "axios";

const AgendarChamada = () => {
  const [dataHora, setDataHora] = useState("");
  const [nomeLaudo, setNomeLaudo] = useState(""); // Estado para o nome do laudo
  const [response, setResponse] = useState(null); // Estado compartilhado para a resposta estruturada
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado para erros

  // Função para agendar chamada
  const agendarChamada = async () => {
    setLoading(true);
    setError("");
    setResponse(null); // Limpa a resposta anterior
    try {
      const res = await axios.post(
        "http://89.116.214.151:3000/IA/v1/agendarChamada",
        {
          horario: dataHora,
          nomeLaudo: nomeLaudo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setResponse({ message: res.data.message }); // Armazena a mensagem de sucesso
    } catch (error) {
      setError(error.response?.data?.message || "Erro ao agendar chamada");
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar agendamentos ativos
  const buscarAgendamentosAtivos = async () => {
    setLoading(true);
    setError("");
    setResponse(null);
    try {
      const res = await axios.get(
        "http://89.116.214.151:3000/IA/v1/agendamentosAtivos"
      );
      setResponse({ type: "ativos", data: res.data.agendamentos }); // Armazena os dados ativos
    } catch (error) {
      setError(
        error.response?.data?.message || "Erro ao buscar agendamentos ativos"
      );
    } finally {
      setLoading(false);
    }
  };

  // Função para buscar agendamentos concluídos
  const buscarAgendamentosConcluidos = async () => {
    setLoading(true);
    setError("");
    setResponse(null);
    try {
      const res = await axios.get(
        "http://89.116.214.151:3000/IA/v1/agendamentosConcluidos"
      );
      setResponse({ type: "concluidos", data: res.data.agendamentos }); // Armazena os dados concluídos
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Erro ao buscar agendamentos concluídos"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="agendamentosPrincipal">
      <h2>Agendamentos</h2>

      {/* Input para o nome do laudo */}
      <input
        type="text"
        value={nomeLaudo}
        onChange={(e) => setNomeLaudo(e.target.value)}
        placeholder="Nome do Laudo"
        className="input"
      />

      {/* Input para selecionar a data e hora */}
      <input
        type="datetime-local"
        value={dataHora}
        onChange={(e) => setDataHora(e.target.value)}
        className="input"
      />

      {/* Grupo de botões */}
      <div className="button-group">
        {/* Botão para agendar chamada */}
        <button onClick={agendarChamada} disabled={loading}>
          {loading ? "Agendando..." : "Agendar"}
        </button>

        {/* Botão para listar agendamentos ativos */}
        <button onClick={buscarAgendamentosAtivos} disabled={loading}>
          {loading ? "Carregando..." : "Agendados"}
        </button>

        {/* Botão para listar agendamentos concluídos */}
        <button onClick={buscarAgendamentosConcluidos} disabled={loading}>
          {loading ? "Carregando..." : "Listar Concluídos"}
        </button>
      </div>

      {/* Exibir a resposta da API ou o erro */}
      <div className="response">
        {loading && <p>Carregando...</p>}
        {error && <p style={{ color: "red" }}>{error}</p>}

        {/* Exibir mensagem de sucesso ao agendar chamada */}
        {response && response.message && (
          <p style={{ color: "green" }}>{response.message}</p>
        )}

        {/* Tabela para agendamentos ativos */}
        {response && response.type === "ativos" && (
          <div>
            <h3>Agendamentos Ativos</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Horário</th>
                  <th>Status</th>
                  <th>Laudo ID</th>
                </tr>
              </thead>
              <tbody>
                {response.data.map((agendamento) => (
                  <tr key={agendamento.id}>
                    <td>{agendamento.id}</td>
                    <td>{agendamento.horario}</td>
                    <td>{agendamento.status}</td>
                    <td>{agendamento.laudo_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Tabela para agendamentos concluídos */}
        {response && response.type === "concluidos" && (
          <div>
            <h3>Agendamentos Concluídos</h3>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Horário</th>
                  <th>Status</th>
                  <th>Laudo ID</th>
                </tr>
              </thead>
              <tbody>
                {response.data.map((agendamento) => (
                  <tr key={agendamento.id}>
                    <td>{agendamento.id}</td>
                    <td>{agendamento.horario}</td>
                    <td>{agendamento.status}</td>
                    <td>{agendamento.laudo_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </section>
  );
};

export default AgendarChamada;
