import React, { useState } from "react";
import axios from "axios";

const baseURL = "http://89.116.214.151:3000";

function GerenciadorDeImagens() {
  const [imagens, setImagens] = useState([]); // Estado para armazenar as imagens e miniaturas
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(""); // Estado de erro
  const [response, setResponse] = useState(""); // Estado único para a resposta
  const [laudos, setLaudos] = useState([]); // Estado para armazenar os laudos disponíveis
  const [selectedLaudo, setSelectedLaudo] = useState(""); // Estado para o laudo selecionado
  const [action, setAction] = useState(""); // Estado para controlar a ação atual (listar ou resetar imagens)

  // Função para buscar descrições das imagens
  const buscarDescricoesDasImagens = async () => {
    setLoading(true);
    setError("");
    try {
      const res = await axios.get(
        `${baseURL}/IA/v1/buscarDescricoesDasImagens`
      );
      setResponse(res.data.contentArray.join("\n"));
    } catch (error) {
      const errorMessage = error.response ? error.response.data : error.message;
      setError(`Erro ao buscar descrições: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  // Função para copiar a resposta
  const copiarResposta = () => {
    if (response) {
      navigator.clipboard
        .writeText(response)
        .then(() => alert("Resposta copiada para a área de transferência!"))
        .catch((err) => console.error("Erro ao copiar: ", err));
    }
  };

  // Função para listar laudos disponíveis (para "Listar Imagens")
  const listarLaudosParaImagens = async () => {
    setLoading(true);
    setError("");
    setAction("listar");
    try {
      const res = await axios.get(`${baseURL}/v1/laudosDisponiveis`);
      if (res.data.laudos && res.data.laudos.length > 0) {
        setLaudos(res.data.laudos);
        setImagens([]); // Limpa as imagens exibidas anteriormente
        setResponse(""); // Limpa a resposta anterior
      } else {
        setError("Nenhum laudo disponível para listar imagens.");
      }
    } catch (error) {
      setError("Erro ao buscar laudos disponíveis.");
    } finally {
      setLoading(false);
    }
  };

  // Função para listar imagens de um laudo específico
  const listarImagensPorLaudo = async () => {
    if (!selectedLaudo) {
      alert("Por favor, selecione um laudo para listar as imagens.");
      return;
    }

    setLoading(true);
    setError("");
    try {
      const res = await axios.get(`${baseURL}/v1/listarImagens`, {
        params: { nomeLaudo: selectedLaudo },
      });
      const imagens = res.data.imagens || [];
      if (imagens.length > 0) {
        setImagens(imagens);
      } else {
        setImagens([]);
        setError(`Nenhuma imagem encontrada para o laudo "${selectedLaudo}".`);
      }
    } catch (error) {
      setError("Erro ao listar imagens.");
    } finally {
      setLoading(false);
    }
  };

  // Função para listar laudos disponíveis (para "Resetar Imagens")
  const listarLaudosParaDelecao = async () => {
    setLoading(true);
    setError("");
    setAction("resetar");
    try {
      const res = await axios.get(`${baseURL}/v1/laudosDisponiveis`);
      if (res.data.laudos && res.data.laudos.length > 0) {
        setLaudos(res.data.laudos);
      } else {
        setError("Nenhum laudo disponível para deletar.");
      }
    } catch (error) {
      setError("Erro ao buscar laudos disponíveis.");
    } finally {
      setLoading(false);
    }
  };

  // Função para resetar imagens de um laudo específico
  const deletarImagens = async () => {
    if (!selectedLaudo) {
      alert("Por favor, selecione um laudo para deletar as imagens.");
      return;
    }
    setLoading(true);
    setError("");
    try {
      const res = await axios.delete(`${baseURL}/v1/deletarImagens`, {
        data: { nomeLaudo: selectedLaudo },
      });
      setResponse(`Imagens do laudo "${selectedLaudo}" resetadas com sucesso.`);
    } catch (error) {
      setError("Erro ao resetar imagens.");
    } finally {
      setLoading(false);
      setSelectedLaudo(""); // Limpa o laudo selecionado
      setLaudos([]); // Limpa a lista de laudos após resetar
      setAction(""); // Reseta a ação após concluir
    }
  };

  return (
    <div className="container">
      <div id="button-group-buscar-descricoes-imagens" className="button-group">
        <button onClick={buscarDescricoesDasImagens} disabled={loading}>
          {loading ? "Carregando..." : "Buscar Descrições"}
        </button>
        <button onClick={copiarResposta} disabled={!response}>
          Copiar
        </button>
        <button onClick={listarLaudosParaImagens} disabled={loading}>
          {loading ? "Carregando..." : "Listar Imagens"}
        </button>
        <button onClick={listarLaudosParaDelecao} disabled={loading}>
          {loading ? "Carregando..." : "Resetar Imagens"}
        </button>
      </div>

      {/* Seleção de laudo para listar ou resetar imagens */}
      {laudos.length > 0 && (
        <div className="laudo-selector">
          <h3>Selecione o laudo desejado:</h3>
          <select
            value={selectedLaudo}
            onChange={(e) => setSelectedLaudo(e.target.value)}
          >
            <option value="">Escolha um laudo</option>
            {laudos.map((laudo) => (
              <option key={laudo.laudo_id} value={laudo.nome}>
                {laudo.nome}
              </option>
            ))}
          </select>
          {action === "listar" && (
            <button
              onClick={listarImagensPorLaudo}
              disabled={!selectedLaudo || loading}
            >
              {loading ? "Carregando..." : "Listar Imagens"}
            </button>
          )}
          {action === "resetar" && (
            <button
              onClick={deletarImagens}
              disabled={!selectedLaudo || loading}
            >
              {loading ? "Resetando..." : "Resetar Imagens"}
            </button>
          )}
        </div>
      )}

      <div id="response-buscar-descricoes-imagens" className="response">
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <pre>{response}</pre>
            <div className="image-list">
              {imagens.map((imagem, index) => (
                <div key={index} className="image-item">
                  <p>{imagem.nome}</p>
                  <img src={imagem.thumbnail} alt={imagem.nome} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default GerenciadorDeImagens;
