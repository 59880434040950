import React, { useState } from "react";
import axios from "axios";
import minhaImagem from "../logo-mariah.png"; // Caminho da imagem
import logo_mariah_ia from "../mariah-ia-logo.png"; // Caminho da imagem

const baseURL = "http://89.116.214.151:3000/v1";

function EnviarImagens() {
  const [responseMessage, setResponseMessage] = useState(""); // Para mensagens amigáveis
  const [uploadedImages, setUploadedImages] = useState([]); // Para armazenar as imagens enviadas
  const [nomeLaudo, setNomeLaudo] = useState("");
  const [ambiente, setAmbiente] = useState("");
  const [loading, setLoading] = useState(false);

  const enviarImagens = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    if (!nomeLaudo || !ambiente) {
      setResponseMessage("Por favor, preencha o nome do laudo e o ambiente.");
      return;
    }

    formData.append("nomeLaudo", nomeLaudo);
    formData.append("ambiente", ambiente);

    for (let i = 0; i < files.length; i++) {
      formData.append("imagens", files[i]);
    }

    setLoading(true);
    setResponseMessage(""); // Limpa a mensagem anterior

    try {
      const res = await axios.post(`${baseURL}/enviarImagens`, formData);

      // Definir uma mensagem amigável
      setResponseMessage("Imagens enviadas com sucesso!");

      // Atualizar a lista de imagens enviadas para exibir
      const imagensEnviadas = Array.from(files).map((file) => file.name);
      setUploadedImages(imagensEnviadas);
    } catch (error) {
      const errorMsg =
        error.response?.data?.message || "Erro ao enviar as imagens.";
      setResponseMessage(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section>
      <div className="MariahGeral">
        <div className="title-and-image">
          <img
            className="logo_mariah_ia"
            src={logo_mariah_ia}
            alt="Logo Mariah IA"
          />
          <img
            src={minhaImagem}
            alt="Descrição da imagem"
            className="mariah-image"
          />
        </div>

        <h2>Upload de Fotos</h2>

        {/* Campo para inserir o nome do laudo */}
        <input
          type="text"
          value={nomeLaudo}
          onChange={(e) => setNomeLaudo(e.target.value)}
          placeholder="Nome do Laudo"
          disabled={loading}
        />

        {/* Campo para inserir o nome do ambiente */}
        <input
          type="text"
          value={ambiente}
          onChange={(e) => setAmbiente(e.target.value)}
          placeholder="Ambiente"
          disabled={loading}
        />

        {/* Input para enviar as imagens */}
        <input
          type="file"
          multiple
          onChange={enviarImagens}
          disabled={loading}
        />
      </div>

      {/* Exibir a resposta amigável da API */}
      <div className="response">
        {loading ? (
          <p>Enviando imagens...</p>
        ) : (
          <>
            {responseMessage && <p>{responseMessage}</p>}
            {uploadedImages.length > 0 && (
              <div>
                <h4>Imagens Enviadas:</h4>
                <ul>
                  {uploadedImages.map((imageName, index) => (
                    <li key={index}>{imageName}</li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default EnviarImagens;
