import React, { useState } from "react";
import axios from "axios";

const RemoverAgendamento = () => {
  const [agendamentoId, setAgendamentoId] = useState("");
  const [responseMessage, setResponseMessage] = useState(""); // Para mensagem amigável de resposta
  const [errorMessage, setErrorMessage] = useState(""); // Para mensagem amigável de erro
  const [loading, setLoading] = useState(false); // Estado de carregamento

  const removerAgendamento = async () => {
    if (!agendamentoId) {
      setErrorMessage("Por favor, insira o ID do agendamento.");
      return;
    }

    setLoading(true); // Inicia o estado de carregamento
    setErrorMessage(""); // Limpa o erro antes da nova requisição
    setResponseMessage(""); // Limpa a resposta antes da nova requisição

    try {
      const res = await axios.delete(
        `http://89.116.214.151:3000/IA/v1/removerAgendamento/${agendamentoId}`
      );
      setResponseMessage(`Agendamento ${agendamentoId} removido com sucesso.`);
    } catch (err) {
      const errorMsg =
        err.response?.data?.message || "Erro ao remover o agendamento.";
      setErrorMessage(errorMsg);
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <section>
      <h2>Remover Agendamento</h2>

      {/* Input para o ID do agendamento */}
      <input
        type="text"
        value={agendamentoId}
        onChange={(e) => setAgendamentoId(e.target.value)}
        placeholder="Digite o ID do agendamento"
        className="input"
        disabled={loading}
      />

      {/* Botão para remover o agendamento */}
      <button onClick={removerAgendamento} disabled={loading}>
        {loading ? "Removendo..." : "Remover Agendamento"}
      </button>

      {/* Exibir mensagens de resposta amigáveis */}
      <div className="response">
        {loading && <p>Processando a remoção...</p>}
        {responseMessage && <p style={{ color: "green" }}>{responseMessage}</p>}
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
    </section>
  );
};

export default RemoverAgendamento;
